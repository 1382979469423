const defaultCardCSS = {
    width: "8.8cm",
    height: "6.35cm",
    border: "0.1cm solid black",
    backgroundColor: "white",
    
    boxSizing: "border-box",
    position: "relative",
}

export default defaultCardCSS;